
import ApiService from "../../../core/services/ApiService";
import { defineComponent, onMounted, reactive } from "vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ElMessage } from "element-plus";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { setOptionsValues } from "@/components/multiselect-options/Options";

interface Customer {
  is_guest: boolean;
  language_id: number;
  browser_country_id: number;
}

interface ComponentData {
  selectedCountry: number | null;
  selectedLanguage: number | null;
  customer: Customer;
  countries: MultiselectOptions[] | null;
  languages: MultiselectOptions[] | null;
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "CustomerCreatePage",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    Multiselect,
  },

  setup() {
    const router = useRouter();
    const state = reactive<ComponentData>({
      selectedCountry: null,
      selectedLanguage: null,
      customer: {
        is_guest: true,
        language_id: 1,
        browser_country_id: 1,
      },
      countries: [],
      languages: [],
      submitButtonLoading: false,
    });

    const { handleSubmit, setFieldError } = useForm();
    const createCustomer = handleSubmit((values) => {
      values.is_guest = state.customer.is_guest;
      values.browser_country_id = state.selectedCountry;
      values.language_id = state.selectedLanguage;

      state.submitButtonLoading = true;
      setFieldError("language_id", undefined);
      setFieldError("browser_country_id", undefined);
      ApiService.post(`customer`, values)
        .then(({ data }) => {
          ElMessage.success("Customer added successfully!");
          router.push({
            name: "customers-view",
            params: { id: data.id },
          });
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const getCountries = (): void => {
      ApiService.get(`address/countries`)
        .then(({ data }) => {
          state.countries = setOptionsValues(data.countries);
        })
        .catch((error) => Notify.apiError(error));
    };

    const getLanguages = (): void => {
      ApiService.get(`languages/`)
        .then(({ data }) => {
          state.languages = setOptionsValues(data.languages);
        })
        .catch((error) => Notify.apiError(error));
    };

    const updateCountry = (value): void => {
      state.selectedCountry = value;
      state.customer.browser_country_id = value;
    };

    const updateLanguage = (value): void => {
      state.selectedLanguage = value;
      state.customer.language_id = value;
    };

    onMounted(() => {
      getCountries();
      getLanguages();
    });

    return {
      state,
      createCustomer,
      updateCountry,
      updateLanguage,
    };
  },
});
