<template>
  <div class="container bg-white p-5">
    <h1>Create customer:</h1>
    <small>All fields are required</small>
    <!--    <form action="">-->
    <form @submit="createCustomer">
      <div class="mb-5">
        <label for="email" class="form-label"> Email address </label>
        <Field
          id="email"
          type="email"
          class="form-control form-control-solid"
          name="email"
          placeholder="name@example.com"
          :value="state.customer.email"
          required
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>

        <div class="row mb-5">
          <div class="col">
            <label for="first_name" class="form-label"> Name </label>
            <Field
              id="first_name"
              type="text"
              class="form-control form-control-solid"
              name="first_name"
              placeholder="Name"
              :value="state.customer.first_name"
              required
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="first_name" />
              </div>
            </div>
          </div>

          <div class="col">
            <label for="last_name" class="form-label"> Surname </label>
            <Field
              id="last_name"
              type="text"
              class="form-control form-control-solid"
              name="last_name"
              placeholder="Surname"
              :value="state.customer.last_name"
              required
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="last_name" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col">
          <label for="language_id" class="form-label"> Language </label>

          <Multiselect
            :options="state.languages"
            :searchable="true"
            name="language_id"
            @change="updateLanguage"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="language_id"></ErrorMessage>
            </div>
          </div>
        </div>

        <div class="col">
          <label for="browser_country_id" class="form-label">
            Browser country
          </label>
          <Multiselect
            :options="state.countries"
            :searchable="true"
            name="browser_country_id"
            @change="updateCountry"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="browser_country_id"></ErrorMessage>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="password" class="form-label"> Password </label>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <Field
            id="password"
            type="password"
            class="form-control form-control-solid"
            name="password"
            :value="state.customer.password"
            autocomplete="off"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="password"></ErrorMessage>
            </div>
          </div>
        </div>

        <div class="col d-flex align-items-center">
          <div class="form-check form-check-custom form-check-solid">
            <input
              id="is_guest"
              v-model="state.customer.is_guest"
              class="form-check-input"
              type="checkbox"
              name="is_guest"
              autocomplete="off"
            />
            <label class="form-check-label" for="is_guest"> Is guest </label>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="is_guest"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form-submit-button :loading="submitButtonLoading">
        Create customer
      </form-submit-button>
    </form>
  </div>
</template>

<script lang="ts">
import ApiService from "../../../core/services/ApiService";
import { defineComponent, onMounted, reactive } from "vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ElMessage } from "element-plus";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import { setOptionsValues } from "@/components/multiselect-options/Options";

interface Customer {
  is_guest: boolean;
  language_id: number;
  browser_country_id: number;
}

interface ComponentData {
  selectedCountry: number | null;
  selectedLanguage: number | null;
  customer: Customer;
  countries: MultiselectOptions[] | null;
  languages: MultiselectOptions[] | null;
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "CustomerCreatePage",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    Multiselect,
  },

  setup() {
    const router = useRouter();
    const state = reactive<ComponentData>({
      selectedCountry: null,
      selectedLanguage: null,
      customer: {
        is_guest: true,
        language_id: 1,
        browser_country_id: 1,
      },
      countries: [],
      languages: [],
      submitButtonLoading: false,
    });

    const { handleSubmit, setFieldError } = useForm();
    const createCustomer = handleSubmit((values) => {
      values.is_guest = state.customer.is_guest;
      values.browser_country_id = state.selectedCountry;
      values.language_id = state.selectedLanguage;

      state.submitButtonLoading = true;
      setFieldError("language_id", undefined);
      setFieldError("browser_country_id", undefined);
      ApiService.post(`customer`, values)
        .then(({ data }) => {
          ElMessage.success("Customer added successfully!");
          router.push({
            name: "customers-view",
            params: { id: data.id },
          });
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const getCountries = (): void => {
      ApiService.get(`address/countries`)
        .then(({ data }) => {
          state.countries = setOptionsValues(data.countries);
        })
        .catch((error) => Notify.apiError(error));
    };

    const getLanguages = (): void => {
      ApiService.get(`languages/`)
        .then(({ data }) => {
          state.languages = setOptionsValues(data.languages);
        })
        .catch((error) => Notify.apiError(error));
    };

    const updateCountry = (value): void => {
      state.selectedCountry = value;
      state.customer.browser_country_id = value;
    };

    const updateLanguage = (value): void => {
      state.selectedLanguage = value;
      state.customer.language_id = value;
    };

    onMounted(() => {
      getCountries();
      getLanguages();
    });

    return {
      state,
      createCustomer,
      updateCountry,
      updateLanguage,
    };
  },
});
</script>
